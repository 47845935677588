<template>
  <form
    class="p-4"
    @submit.prevent="handleSubmit"
    v-shortkey="saveShortkey"
    @shortkey="handleSubmit"
  >
    <fieldset class="form-row">
      <div class="col">
        <legend>
          {{ isEditing ? "Edit" : "Add" }} Prefix
          <loader v-if="isLoading" size="small" class="mx-auto"></loader>
        </legend>
        <div class="row">
          <select-input
            class="col"
            label="Type"
            v-model="prefix.prefixTypeId"
            :dataSource="prefixTypes"
            :validator="$v.prefix.prefixTypeId"
            :noTemplate="true"
            name="prefixTypeId"
            displayExpr="displayName"
          />
          <select-input
            v-if="cytologyModuleEnabled"
            class="col"
            label="Cytology Type"
            v-model="prefix.cytTypeId"
            :dataSource="cytologyTypes"
            name="cytTypeId"
            displayExpr="displayName"
          />
          <text-input
            class="col"
            name="code"
            label="Code"
            v-model="prefix.code"
            :disabled="isEditing"
            :validator="$v.prefix.code"
            maxLength="6"
          />
          <text-input
            v-if="!isPathologyNumbering"
            class="col"
            name="group"
            label="Group"
            :disabled="isEditing"
            v-model="prefix.group"
            :validator="$v.prefix.group"
            maxLength="6"
          />
          <div v-else class="col" />
        </div>
        <div class="row">
          <text-input
            class="col"
            label="Description"
            name="description"
            :validator="$v.prefix.description"
            v-model="prefix.description"
            maxLength="251"
          />
          <tag-input
            class="col"
            name="prefixTags"
            label="Tags"
            v-model="prefix.prefixTagIds"
            :dataSource="prefixTags"
          />
          <text-input class="col" name="title" label="Title" v-model="prefix.title" />
        </div>
      </div>
      <fieldset class="col d-flex">
        <legend class="">Templates</legend>
        <div class="col">
          <select-input
            label="Path Report Template"
            :dataSource="reportsSearch"
            name="pathReportTemplate"
            v-model="prefix.pathReportTemplateId"
            class="col"
            displayExpr="name"
          />
          <select-input
            label="Slide Label Template"
            class="col"
            :dataSource="reportsSearch"
            name="slideLabelTemplate"
            v-model="prefix.slideLabelTemplateId"
            displayExpr="name"
          />
          <select-input
            class="col"
            label="Slide Glass Label Template"
            :dataSource="reportsSearch"
            name="slideGlassTemplateId"
            v-model="prefix.slideGlassTemplateId"
            displayExpr="name"
          />
          <select-input
            class="col"
            label="Req. Label Template"
            :dataSource="reportsSearch"
            name="defaultReqLabelId"
            v-model="prefix.defaultReqLabelId"
            displayExpr="name"
          />
          <select-input
            class="col"
            label="Default Contact"
            v-model="prefix.defaultContactId"
            :dataSource="contactDataSource"
            @searchChange="contactSearchChange"
            searchExpr="displayName"
          />
        </div>
        <div class="col">
          <select-input
            class="col"
            name="defaultSpecLabelId"
            label="Specimen Label Template"
            :dataSource="reportsSearch"
            v-model="prefix.defaultSpecLabelId"
            displayExpr="name"
          />
          <select-input
            class="col"
            name="specLabelPrinterId"
            label="Specimen Label Printer"
            :dataSource="labPrinters"
            v-model="prefix.specLabelPrinterId"
            displayExpr="displayName"
            valueExpr="printerId"
          />
          <select-input
            label="Cassette Label Template"
            name="defaultCassetteLabelId"
            :dataSource="reportsSearch"
            v-model="prefix.defaultCassetteLabelId"
            displayExpr="name"
            class="col"
          />
          <!-- <text-input
            class="col"
            label="Slide Tray"
            displayExpr="name"
            name="slideLabelBin"
            v-model="prefix.slideLabelBin"
          /> -->
          <select-input
            class="col"
            label="Default Bin Map"
            displayExpr="name"
            name="binMapId"
            v-model="prefix.binMapId"
            :items="labBinMaps"
          />
        </div>
      </fieldset>
    </fieldset>

    <div class="form-row">
      <fieldset class="col">
        <legend class="">Path Report Options</legend>
        <checkbox label="Hide History" id="ssn" v-model="prefix.hideHistory" />
      </fieldset>
      <fieldset class="col">
        <legend class="">Quick Options</legend>
        <checkbox label="Social Security Number" id="ssn" v-model="prefix.isQuickRegisterSSN" />
        <checkbox
          label="Private"
          id="setCaseToPrivate"
          name="setCaseToPrivate"
          v-model="prefix.setCaseToPrivate"
        />
        <checkbox label="MRN" id="mrn" v-model="prefix.isQuickRegisterMRN" />
        <checkbox label="Date of Birth" id="DOB" v-model="prefix.isQuickRegisterDOB" />
        <checkbox
          label="Medical History Match"
          id="medicalRecordHistoryMatch"
          name="medicalRecordHistoryMatch"
          v-model="prefix.medicalRecordHistoryMatch"
        />
      </fieldset>

      <fieldset class="col">
        <legend class="">Distribution Options</legend>
        <checkbox
          label="Printing"
          id="printing"
          name="printing"
          :value="!prefix.doNotPrint"
          @input="handleOppValue($event, 'doNotPrint')"
        />
        <checkbox
          label="Fax"
          id="fax"
          name="fax"
          :value="!prefix.doNotFax"
          @input="handleOppValue($event, 'doNotFax')"
        />
        <checkbox
          label="Email"
          id="email"
          name="email"
          :value="!prefix.doNotEmail"
          @input="handleOppValue($event, 'doNotEmail')"
        />
        <checkbox
          label="HL7"
          id="hl7"
          name="hl7"
          :value="!prefix.doNotSendHL7"
          @input="handleOppValue($event, 'doNotSendHL7')"
        />
        <checkbox
          label="File Drop"
          id="pdfFileDrop"
          name="pdfFileDrop"
          :value="!prefix.doNotSendPdfFileDrop"
          @input="handleOppValue($event, 'doNotSendPdfFileDrop')"
        />
        <checkbox
          label="Auto Report"
          id="isAutoReport"
          name="isAutoReport"
          :disabled="distributionSelected"
          v-model="prefix.isAutoReport"
        />
      </fieldset>

      <fieldset class="col">
        <legend class="">Misc. Options</legend>
        <checkbox
          label="Show To Lab Client Users"
          id="doNotShowToLabClientUser"
          name="doNotShowToLabClientUser"
          :value="!prefix.doNotShowToLabClientUser"
          @input="handleOppValue($event, 'doNotShowToLabClientUser')"
        />
        <checkbox
          label="Protocol Required"
          id="isProtocolRequired"
          name="isProtocolRequired"
          v-model="prefix.isProtocolRequired"
        />
        <checkbox
          label="Site Required"
          id="isSiteRequired"
          name="isSiteRequired"
          v-model="prefix.siteRequired"
        />
        <checkbox
          label="Gender Required"
          id="genderRequiredAtAccessioning"
          name="genderRequiredAtAccessioning"
          v-model="prefix.genderRequiredAtAccessioning"
        />
        <checkbox
          label="No Blocks"
          id="noBlocks"
          name="noBlocks"
          v-model="prefix.markCassettesAsPrinted"
        />
        <checkbox
          label="No Prelim Signout"
          id="preventPrelimSignout"
          name="preventPrelimSignout"
          v-model="prefix.preventPrelimSignout"
        />
        <checkbox
          label="Collected Optional at Acc."
          id="collectionDateOptionalAtAccessioning"
          name="collectionDateOptionalAtAccessioning"
          v-model="prefix.collectionDateOptionalAtAccessioning"
        />
        <checkbox
          v-if="labSettings.RegistrationNoteTagId"
          label="Hide Registration Note"
          id="hideRegistrationNoteAtAccession"
          name="hideRegistrationNoteAtAccession"
          v-model="prefix.hideRegistrationNoteAtAccession"
        />
      </fieldset>
      <fieldset class="d-flex col-5">
        <legend>Printing Options</legend>
        <div class="mr-2">
          <h5>Accession</h5>
          <checkbox
            style="width: max-content"
            label=" Specimen Label"
            name="printSpecimenLabel"
            v-model="prefix.printSpecLabel"
            id="psl"
          />
          <checkbox
            style="width: max-content"
            label=" Requisition  Label"
            name="printReqLabel"
            v-model="prefix.printReqLabel"
            id="prl"
          />
          <checkbox label=" Slides" id="labelPrinter" v-model="prefix.printSlideLabels" />
          <checkbox
            label=" Cassettes"
            id="cassettePrinter"
            name="cassettePrinter"
            v-model="prefix.printCassetteLabels"
          />
        </div>
        <div>
          <h5 class="mb-1" style="width: max-content">Path Report</h5>
          <checkbox
            label="Prelim"
            name="printPrelim"
            v-model="prefix.printPrelim"
            id="printPrelim"
          />
          <checkbox label="Final" name="printFinal" v-model="prefix.printFinal" id="printFinal" />
        </div>
        <div class="">
          <tag-input
            class="col"
            name="printerIds"
            id="printersId"
            label="Printers"
            :maxDisplayedTags="3"
            :dataSource="labPrinters"
            valueExpr="printerId"
            v-model="prefix.printerIds"
          />
          <div class="d-flex">
            <number-input
              class="col"
              label="Req. Copies"
              v-model="prefix.reqLabelCopies"
              name="reqLabelCopies"
              id="reqLabelCopies"
            />
            <number-input
              class="col"
              label="Spec. Copies"
              v-model="prefix.specLabelCopies"
              name="specLabelCopies"
              id="specLabelCopies"
            />
          </div>
          <div class="d-flex">
            <date-picker
              class="col"
              :validator="$v.prefix.printStart"
              label="Print Start"
              name="printStart"
              v-model="prefix.printStart"
              :max="prefix.printEnd"
            />
            <date-picker
              :min="prefix.printStart"
              class="col"
              name="printEnd"
              label="Print End"
              v-model="prefix.printEnd"
            />
          </div>
        </div>
      </fieldset>
    </div>

    <div class="my-2 d-flex border p-4">
      <div class="col border-right">
        <h4>Billing</h4>
        <div class="form-row">
          <div class="d-flex col justify-content-between align-self-center flex-wrap">
            <checkbox label="Enabled" id="billing_enabled" v-model="prefix.isBillingEnabled" />
            <checkbox label="Automatic" id="billing_automatic" v-model="prefix.isBillingAuto" />
            <checkbox
              label="Electronic"
              id="billing_electronic"
              v-model="prefix.isBillingElectronic"
            />
            <checkbox
              label="Add Suffix To CPT"
              id="addSuffixToCPTCode"
              name="addSuffixToCPTCode"
              v-model="prefix.addSuffixToCPTCode"
            />
          </div>
          <select-input
            name="fixedCPT"
            class="col"
            label="Fixed CPT"
            :multiple="false"
            :searchExpr="cptSearch"
            :displayExpr="displayCpt"
            :dataSource="cptStore"
            v-model="selectedFixedCPTCode"
            :disabled="hasFixedCpts"
          />
        </div>
        <div class="form-row">
          <select-input
            name="replacementCPTIfOrder"
            class="col"
            label="Replacement CPT"
            :multiple="false"
            :searchExpr="cptSearch"
            :displayExpr="displayCpt"
            :dataSource="cptStore"
            v-model="selectedReplacementCPTCode"
            :disabled="hasFixedCpts"
          />
          <text-input label="POS" name="billingPOS" class="col" v-model="prefix.billingPOS" />
          <text-input name="billingTOS" label="TOS" class="col" v-model="prefix.billingTOS" />
        </div>
        <div class="row">
          <div class="cptPairGrid col">
            <label for="cptPairGrid" class="cptPairLabel">
              <b>CPT Code Pairs</b>
            </label>
            <DxGridWithSearch
              label="Cpt Code Pairs"
              class="pt-0"
              name="cptPairGrid"
              :dataSource="cptPairDataSource"
              :columns="cptPairColumns"
              :editing="editing"
              :searchPanel="null"
              :columnChooser="null"
              :toolbar="cptToolbar"
              :allowColumnReordering="false"
              :showFilterRow="false"
              :selection="null"
              @initialized="initCptPairGrid"
            >
              <template v-slot:extraActions>
                <AddButton type="button" @click.prevent="addCptPair" />
              </template>
              <template v-slot:actions="{ data }">
                <div class="d-flex align-items-center justify-content-center">
                  <IconButton
                    v-tooltip.right.start="'Delete item.'"
                    @click.prevent="deleteCptPair(data.rowIndex)"
                    class="mx-1 p-0 text-danger pointer"
                    icon="trash-alt"
                  />
                </div>
              </template>
              <template v-slot:selectCptTemplate="{ data }">
                <select-input
                  class="dropdown"
                  :value="data.value"
                  :dataSource="cptStore"
                  display-expr="code"
                  :name="data.column.dataField"
                  :data-row-index="data.rowIndex"
                  @input="handleSelectCptPairItem($event, data.rowIndex, data.column.dataField)"
                  :openOnMount="true"
                />
              </template>
            </DxGridWithSearch>
          </div>
          <div class="cptPairGrid col">
            <label for="fixedCptGrid" class="cptPairLabel">
              <b>Fixed CPT Codes</b>
            </label>
            <DxGridWithSearch
              class="pt-0"
              name="fixedCptGrid"
              :dataSource="fixedCptDataSource"
              :columns="fixedCptColumns"
              :editing="editing"
              :searchPanel="null"
              :columnChooser="null"
              :toolbar="cptToolbar"
              :allowColumnReordering="false"
              :showFilterRow="false"
              :selection="null"
              @initialized="initFixedCptGrid"
            >
              <template v-slot:extraActions>
                <AddButton type="button" @click.prevent="addFixedCpt" />
              </template>
              <template v-slot:actions="{ data }">
                <div class="d-flex align-items-center justify-content-center">
                  <IconButton
                    v-tooltip.right.start="'Delete item.'"
                    @click.prevent="deleteFixedCpt(data.rowIndex)"
                    class="mx-1 p-0 text-danger pointer"
                    icon="trash-alt"
                  />
                </div>
              </template>
              <template v-slot:selectCptTemplate="{ data }">
                <select-input
                  class="dropdown"
                  :value="data.value"
                  :dataSource="cptStore"
                  display-expr="code"
                  :name="data.column.dataField"
                  :data-row-index="data.rowIndex"
                  @input="handleSelectFixedCptItem($event, data.rowIndex, data.column.dataField)"
                  :openOnMount="true"
                />
              </template>
            </DxGridWithSearch>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="form-row">
          <h4 class="col">Administrative</h4>
        </div>
        <div class="form-row">
          <div class="d-flex col align-items-end">
            <select-input
              :dataSource="protocolOptions"
              label="Default Protocol"
              name="protocol"
              displayExpr="macroName"
              valueExpr="macroId"
              v-model="prefix.protocolId"
            />
          </div>
          <simple-select-input
            name="numberingType"
            class="col"
            :validator="$v.prefix.numberingType"
            label="Numbering Method"
            v-model="prefix.numberingType"
            :items="numberingMethods"
          />
          <div class="d-flex flex-column justify-content-start">
            <text-input
              class="col"
              v-if="isSuperUser"
              label="Last Number Used"
              v-model="prefix.lastNumberUsed"
            />
            <h6 v-else-if="parseInt(prefix.lastNumberUsed)">
              <b>Last Number Used:</b>
              {{ prefix.lastNumberUsed }}
            </h6>
          </div>
        </div>
        <div class="form-row">
          <date-picker
            label="Effective On"
            name="effectiveOn"
            class="col"
            v-model="prefix.effectiveOn"
          />
          <date-picker
            name="expiresOn"
            label="Expires On"
            class="col"
            v-model="prefix.expiryOn"
            :validator="$v.prefix.expiryOn"
          />
        </div>
      </div>
    </div>

    <div class="p-4 border">
      <div class="form-row align-items-end">
        <select-input
          class="col"
          label="orders"
          v-model="selectedOrder"
          :dataSource="procedureSearch"
          name="selectedOrder"
          displayExpr="description"
        />
        <icon-button
          @click="addOrder"
          v-tooltip="'Add order'"
          type="button"
          class="btn text-primary"
          icon="plus"
        />

        <select-input
          :multiple="false"
          name="selectedPanels"
          class="col"
          v-model="selectedPanels"
          label="Panels"
          :dataSource="panelsSearch"
          valueExpr="macroId"
          displayExpr="macroName"
        />

        <icon-button
          type="button"
          @click="addPanel"
          v-tooltip="'Add Panel orders'"
          class="btn text-primary"
          icon="plus"
        />
      </div>
      <div class="form-row my-4">
        <prop-table
          :canEdit="false"
          class="col"
          v-model="prefix.procedurePanels"
          :columns="procedureColumns"
        />
      </div>
    </div>
    <PrefixCompletetionModeForm v-model="prefix" class="border my-2" />
    <div class="form-row justify-content-end">
      <button class="btn btn-danger" @click="handleCancel" type="button">Cancel</button>
      <button type="submit" class="mx-2 btn btn-primary">Submit</button>
    </div>
  </form>
</template>

<script>
import PropTable from "../../common/PropTable.vue";
import { required, maxLength, alpha, helpers } from "vuelidate/lib/validators";
import { afterEffective, altKey, isSuperUser, oneYearAgo } from "@/modules/helpers";
import { mapState } from "vuex";
import ReportService from "../../../services/Reports";
import { cloneDeep } from "lodash";
import DataSource from "devextreme/data/data_source";
import { createLogComment, createLogItem, dateRangeFilter } from "../../../modules/helpers";
import {
  BillingApi,
  DropdownApi,
  MacrosApi,
  PrefixApi,
  PrintersApi,
  SettingsApi,
  AuditLogApi
} from "@/services/index";
import SelectInput from "@/components/common/SelectInput.vue";
import Checkbox from "@/components/common/Checkbox.vue";
import TextInput from "@/components/common/TextInput.vue";
import Loader from "@/components/common/Loader.vue";
import SimpleSelectInput from "@/components/common/SimpleSelectInput.vue";
import NumberInput from "@/components/common/NumberInput.vue";
import IconButton from "@/components/common/IconButton.vue";
import PrefixCompletetionModeForm from "./PrefixCompletetionModeForm.vue";
import DatePicker from "@/components/common/DatePicker.vue";
import TagInput from "@/components/common/TagInput.vue";
import {
  AccessionNumberingTypeEnum,
  CaseCompletionModes,
  CytTypeEnum,
  enumToDropDown,
  TypeCodeGroupEnum
} from "@/modules/enums";
import ArrayStore from "devextreme/data/array_store";
import DxGridWithSearch from "@/components/common/DxGridWithSearch.vue";
import AddButton from "@/components/common/AddButton.vue";
import { handleErrors } from "@/modules/handleErrors";

export default {
  name: "Prefix-Form",
  components: {
    PropTable,
    SelectInput,
    Checkbox,
    TextInput,
    Loader,
    SimpleSelectInput,
    NumberInput,
    IconButton,
    PrefixCompletetionModeForm,
    DatePicker,
    TagInput,
    DxGridWithSearch,
    AddButton
  },
  props: ["prefixId", "prefixToClone"],
  data: () => ({
    pathReport: null,
    slideLabelTemplate: null,
    isLoading: false,
    cytologyTypes: enumToDropDown(CytTypeEnum),
    selectedPathReport: null,
    selectedSlideLabelReport: null,
    selectedFixedCPTCode: null,
    selectedReplacementCPTCode: null,
    originalPrefix: {},
    procedures: [],
    oppBooleanOptions: [
      {
        value: false,
        displayName: "Yes"
      },
      {
        value: true,
        displayName: "no"
      }
    ],
    labPrinters: [],
    prefix: {
      labId: "",
      cassetteLabelBin: "",
      slideLabelBin: "",
      cytTypeId: 5,
      printSlideLabels: true,
      printCassetteLabels: true,
      isProtocolRequired: false,
      siteRequired: true,
      prefixType: {},
      code: "",
      group: "",
      description: "",
      hideHistory: false,
      isQuickRegisterSSN: false,
      isQuickRegisterMRN: false,
      isQuickRegisterDOB: false,
      medicalRecordHistoryMatch: false,
      isSlidePrepsOnly: false,
      pathReportTemplateId: null,
      slideLabelTemplateId: null,
      doNotPrint: false,
      doNotFax: false,
      doNotEmail: false,
      reqLabelCopies: 0,
      specLabelCopies: 0,
      printReqLabel: false,
      printPrelim: false,
      printFinal: false,
      printSpecLabel: true,
      isBillingEnabled: false,
      isBillingAuto: false,
      isBillingElectronic: false,
      billingFixedCPT: null,
      billingPOS: "",
      billingTOS: "",
      numberingType: "Automatic",
      effectiveOn: oneYearAgo(),
      expiryOn: null,
      procedurePanels: [],
      defaultReqLabelId: null,
      defaultSpecLabelId: null,
      defaultCassetteLabelId: null,
      caseCompletionModeId: 0,
      site: "",
      grossIsRequired: false,
      pathologistIsRequired: false,
      protocolId: null,
      resultsMacroId: null,
      diagnosis: "",
      blockCount: 0,
      addSuffixToCPTCode: false,
      slideCount: 0,
      pathologistId: null,
      generatePdf: false,
      emails: [],
      faxMachines: [],
      hL7Configs: [],
      pdfFileDrops: [],
      prefixTagIds: [],
      printerIds: [],
      doNotSendHL7: false,
      doNotSendPdfFileDrop: false,
      doNotShowToLabClientUser: false,
      title: "",
      markCassettesAsPrinted: false,
      preventPrelimSignout: false,
      collectionDateOptionalAtAccessioning: false,
      isAutoReport: false,
      customFields: [],
      cptCodePairs: [],
      fixedCpts: [],
      lastNumberUsed: "0",
      hideRegistrationNoteAtAccession: false,
      defaultContactId: null,
      genderRequiredAtAccessioning: false
    },
    procedureSearch: new DataSource({
      store: DropdownApi.searchProcedures,
      filter: dateRangeFilter("effectiveOn", "expiresOn"),
      sort: "description"
    }),
    panelsSearch: new DataSource({
      store: MacrosApi.searchStore,
      filter: [dateRangeFilter("effectiveOn", "expiresOn"), "and", ["macroType", "=", 2]],
      sort: "macroName"
    }),
    cptSearch: ["code", "description"],
    prefixStore: PrefixApi.searchStore,
    selectedOrder: null,
    selectedPanels: null,
    lastNumberUsed: "0",
    prefixTypes: [],
    panels: [],
    cptCodes: [],
    labBinMaps: [],
    numberingMethods: [
      {
        id: "Automatic",
        displayName: "Automatic"
      },
      {
        id: "Manual",
        displayName: "Manual"
      }
    ],
    saveShortkey: altKey("s"),
    cptPairGrid: {},
    fixedCptGrid: {}
  }),
  validations() {
    return {
      prefix: {
        prefixTypeId: {
          required
        },
        printStart: {
          required: value => (this.prefix.caseCompletionModeId ? helpers.req(value) : true)
        },
        code: {
          required,
          maxLength: maxLength(5),
          alpha,
          prefixCodeExists: value => {
            if (!value) {
              return true;
            }
            const hasSameCode = this.prefixes.find(
              e => e.code.toUpperCase() === value.toUpperCase()
            );
            if (hasSameCode) {
              return hasSameCode.id === this.prefix?.id;
            }
            return true;
          }
        },
        group: {
          required,
          maxLength: maxLength(5),
          alpha
        },
        description: {
          required,
          maxLength: maxLength(250)
        },
        effectiveOn: {
          required
        },
        expiryOn: {
          afterEffective
        },
        numberingType: {
          required
        },
        billingPOS: {
          maxLength: maxLength(30)
        },
        billingTOS: {
          maxLength: maxLength(30)
        },
        resultsMacroId: {
          required: value => (this.prefix.caseCompletionModeId ? helpers.req(value) : true)
        },
        protocolId: {
          required:
            this.prefix.caseCompletionModeId === 2
              ? false
              : value => (this.prefix.caseCompletionModeId ? helpers.req(value) : true)
        },
        pathologistId: {
          required: value =>
            this.prefix.caseCompletionModeId === CaseCompletionModes.Accession
              ? helpers.req(value)
              : true
        }
      }
    };
  },
  domStreams: ["cptSelect$"],
  created() {
    PrintersApi.getLabPrinters().then(res => {
      this.labPrinters = new DataSource({
        store: res || [],
        sort: ["displayName"]
      });
    });
    PrintersApi.getLabBinMaps().then(res => {
      this.labBinMaps = res;
    });
    SettingsApi.typeCodesSearch
      .load({ filter: ["typeId", "=", TypeCodeGroupEnum.Prefix] })
      .then(prefixTypes => {
        this.prefixTypes = prefixTypes;
        if (!this.prefixId) {
          const surgicalType = prefixTypes?.find(e => /surgical/i.test(e.displayName));
          this.prefix.prefixType = surgicalType;
        }
      })
      .then(() => {
        if (this.prefixId) {
          this.isLoading = true;
          PrefixApi.getPrefixById(this.prefixId).then(response => {
            this.prefix = response;
            this.prefixTypeId = response.prefixType?.id;
            this.originalPrefix = cloneDeep(response);
            this.selectedFixedCPTCode = response.billingFixedCPT?.id;
            this.selectedReplacementCPTCode = response.replacementCPTIfOrder?.id;
            this.isLoading = false;
          });
        }
      });
    this.$store.dispatch("dropdowns/getPrefixTags");
    if (Object.keys(this.prefixToClone).length) {
      this.prefix = {
        ...this.prefixToClone,
        code: "",
        description: "",
        group: "",
        title: "",
        lastNumberUsed: "0",
        effectiveOn: oneYearAgo(),
        expiryOn: null
      };
      delete this.prefix.id;
    }
    if (!this.prefixes?.length) {
      this.$store.dispatch("dropdowns/getPrefixes");
    }
  },
  watch: {
    "prefix.code"(nv, ov) {
      if (this.prefix.id) {
        return;
      }
      if (!this.prefix.group || this.prefix.group === ov) {
        this.prefix.group = nv;
      }
    },
    selectedPathReport(nv) {
      if (nv) {
        if (nv?.id !== this.prefix.pathReportTemplateId) {
          this.prefix.pathReportTemplateId = nv.id;
        }
      }
    },
    selectedSlideLabelReport(nv) {
      if (nv) {
        if (nv?.id !== this.prefix.slideLabelTemplateId) {
          this.prefix.slideLabelTemplateId = nv.id;
        }
      }
    },
    distributionSelected: {
      immediate: true,
      handler(nv) {
        if (nv) {
          this.prefix.isAutoReport = false;
        }
      }
    }
  },
  methods: {
    displayType(data) {
      return data && data.displayName;
    },
    displayCpt(data) {
      return data && `${data.code}-${data.description}`;
    },
    handleOppValue(value, prop) {
      this.prefix[prop] = !value;
    },
    async addOrder() {
      if (this.selectedOrder) {
        const existingOrder = this.prefix.procedurePanels?.find(e => e.id === this.selectedOrder);
        if (!existingOrder) {
          const order = await DropdownApi.searchProcedures.byKey(this.selectedOrder);
          this.prefix.procedurePanels.push({
            code: order.description,
            panelProcedure: 0,
            description: "Order",
            id: this.selectedOrder
          });
        } else {
          window.notify("Cannot add duplicate procedure(s) to the list.", "error");
        }
        this.selectedOrder = null;
      }
    },
    async addPanel() {
      if (this.selectedPanels) {
        const existingPanel = this.prefix.procedurePanels?.find(e => e.id === this.selectedPanels);
        if (!existingPanel) {
          const panel = await MacrosApi.searchStore.byKey(this.selectedPanels);
          this.prefix.procedurePanels.push({
            id: this.selectedPanels,
            description: "Panel",
            panelProcedure: 1,
            code: panel.macroName
          });
        } else {
          window.notify("Cannot add duplicate panel(s) to the list.", "error");
        }
        this.selectedPanels = null;
      }
    },
    async handleCancel() {
      const confirm = await window.confirm("Are you sure you want to discard any data entered?");
      if (!confirm) {
        return;
      }
      this.$emit("close");
    },
    async handleSubmit() {
      this.$v.prefix.$touch();
      if (this.$v.prefix.$invalid) {
        window.notify("Invalid input. Please check and try again.", "warning");
        return;
      }
      if (this.selectedFixedCPTCode) {
        this.prefix.billingFixedCPT = {
          id: this.selectedFixedCPTCode
        };
      } else {
        this.prefix.billingFixedCPT = null;
      }
      if (this.selectedReplacementCPTCode) {
        this.prefix.replacementCPTIfOrder = {
          id: this.selectedReplacementCPTCode
        };
      } else {
        this.prefix.replacementCPTIfOrder = null;
      }

      try {
        if (this.prefixId) {
          await PrefixApi.updatePrefix(this.prefix);
          const logItem = createLogItem({}, 5);
          logItem.comments = `${this.prefix.code}:${createLogComment(
            this.originalPrefix,
            this.prefix
          )}`;
          await AuditLogApi.insertLogMessage(logItem);
        } else if (!this.prefixId) {
          this.prefix = await PrefixApi.addPrefix({
            ...this.prefix,
            labId: this.currentLab || this.currentUser?.labId
          });
          const logItem = createLogItem({}, 4);
          logItem.comments = `Created prefix ${this.prefix.code}.\n${JSON.stringify(
            this.prefix,
            null,
            2
          )}`;
          await AuditLogApi.insertLogMessage(logItem);
        }

        if (this.prefix.protocolId) {
          const protocolMacro = await MacrosApi.getMacroDetails(this.prefix.protocolId);
          const targetProtocolIdx = protocolMacro?.prefixes?.findIndex(
            e => e.id === parseInt(this.prefix.id)
          );
          if (targetProtocolIdx === -1) {
            await MacrosApi.editMacro({
              ...protocolMacro,
              prefixes: [...protocolMacro.prefixes, this.prefix]
            });
            await window.alert("Updated protocol to include this prefix in its list.");
          }
        }
        this.$emit("submit");
      } catch (error) {
        handleErrors(error);
      }
    },
    handleSelectCptPairItem(value, index, dataField) {
      const currentPage = this.cptPairGrid.pageIndex();
      const indexToEdit = index + currentPage * 10;
      this.prefix.cptCodePairs[indexToEdit][dataField] = value;
      if (dataField === "oldBtcId") {
        this.cptPairGrid.editCell(index, "newBtcId");
      }
    },
    handleSelectFixedCptItem(value, index, dataField) {
      const currentPage = this.fixedCptGrid.pageIndex();
      const indexToEdit = index + currentPage * 10;
      this.prefix.fixedCpts[indexToEdit][dataField] = value;
      if (dataField === "btcId") {
        this.fixedCptGrid.editCell(index, "quantity");
      }
    },
    addCptPair() {
      if (!this.prefix.cptCodePairs.find(e => !e.oldBtcId || !e.newBtcId)) {
        this.prefix.cptCodePairs.push({ oldBtcId: null, newBtcId: null });
        const currentPageIndex = this.cptPairGrid.pageIndex();
        const lastPageIndex = Math.floor(this.prefix.cptCodePairs.length / 10);
        if (lastPageIndex > currentPageIndex) {
          this.cptPairGrid.pageIndex(lastPageIndex);
        }
        setTimeout(() => {
          const indexToEdit = (this.prefix.cptCodePairs.length - 1) % 10;
          this.cptPairGrid.editCell(indexToEdit, "oldBtcId");
        }, 500);
      }
    },
    addFixedCpt() {
      if (!this.prefix.fixedCpts.find(e => !e.btcId)) {
        this.prefix.fixedCpts.push({ btcId: null, quantity: 1 });
        const currentPageIndex = this.fixedCptGrid.pageIndex();
        const lastPageIndex = Math.floor(this.prefix.fixedCpts.length / 10);
        if (lastPageIndex > currentPageIndex) {
          this.fixedCptGrid.pageIndex(lastPageIndex);
        }
        setTimeout(() => {
          const indexToEdit = (this.prefix.fixedCpts.length - 1) % 10;
          this.fixedCptGrid.editCell(indexToEdit, "btcId");
        }, 500);
      }
    },
    deleteCptPair(index) {
      const currentPage = this.cptPairGrid.pageIndex();
      const indexToEdit = index + currentPage * 10;
      this.prefix.cptCodePairs.splice(indexToEdit, 1);
    },
    deleteFixedCpt(index) {
      this.prefix.fixedCpts.splice(index, 1);
    },
    initCptPairGrid({ component }) {
      this.cptPairGrid = component;
    },
    initFixedCptGrid({ component }) {
      this.fixedCptGrid = component;
    }
  },
  computed: {
    ...mapState(["currentUser", "currentLab"]),
    ...mapState({
      prefixTags: state => state.dropdowns.prefixTags,
      prefixes: state => state.dropdowns.prefixes,
      labSettings: state => state.labSettings,
      cytologyModuleEnabled: state => state.labSettings.CytologyModuleEnabled
    }),
    reportsSearch() {
      return new DataSource({ store: ReportService.searchStore, sort: "name" });
    },
    cptStore() {
      return new DataSource({
        key: "id",
        store: BillingApi.searchStore,
        filter: dateRangeFilter(),
        sort: "code"
      });
    },
    protocolOptions() {
      return new DataSource({
        store: MacrosApi.searchStore,
        paginate: true,
        filter: [["macroType", 3], "and", dateRangeFilter("effectiveOn", "expiresOn")]
      });
    },
    prefixTypeId: {
      get() {
        return this.prefix.prefixType?.id;
      },
      set(value) {
        const type = this.prefixTypes.find(e => e.id === value);
        this.prefix.prefixType = type;
        return;
      }
    },
    isEditing() {
      return Boolean(this.prefixId);
    },
    procedureColumns() {
      return [
        {
          dataField: "code",
          calculateCellValue(option) {
            return option.displayName || option.code;
          }
        },
        {
          dataField: "description",
          calculateCellValue(option) {
            return option.panelProcedure ? "Panel" : "Order";
          }
        }
      ];
    },
    distributionSelected() {
      return (
        !this.prefix.doNotPrint ||
        !this.prefix.doNotFax ||
        !this.prefix.doNotEmail ||
        !this.prefix.doNotSendHL7 ||
        !this.prefix.doNotSendPdfFileDrop
      );
    },
    cptPairColumns() {
      return [
        {
          dataField: "oldBtcId",
          dataType: "number",
          caption: "Inital CPT",
          lookup: {
            dataSource: BillingApi.searchStore,
            valueExpr: "id",
            displayExpr: "code"
          },
          allowEditing: true,
          editCellTemplate: "selectCptTemplate",
          allowSorting: false,
          width: "9em"
        },
        {
          dataField: "newBtcId",
          dataType: "number",
          caption: "Replacement CPT",
          lookup: {
            dataSource: BillingApi.searchStore,
            valueExpr: "id",
            displayExpr: "code"
          },
          allowEditing: true,
          editCellTemplate: "selectCptTemplate",
          allowSorting: false,
          width: "9em"
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions",
          width: "5em"
        }
      ];
    },
    fixedCptColumns() {
      return [
        {
          dataField: "btcId",
          dataType: "number",
          caption: "CPT Code",
          lookup: {
            dataSource: BillingApi.searchStore,
            valueExpr: "id",
            displayExpr: "code"
          },
          allowEditing: true,
          editCellTemplate: "selectCptTemplate",
          allowSorting: false,
          width: "9em"
        },
        {
          dataField: "quantity",
          caption: "Units",
          dataType: "number",
          allowEditing: true,
          allowSorting: false,
          width: "9em"
        },
        {
          type: "buttons",
          caption: "Actions",
          cellTemplate: "actions",
          width: "5em"
        }
      ];
    },
    cptPairDataSource() {
      return new ArrayStore({
        data: this.prefix.cptCodePairs
      });
    },
    fixedCptDataSource() {
      return new ArrayStore({
        data: this.prefix.fixedCpts
      });
    },
    cptToolbar() {
      return {
        items: [
          {
            location: "after",
            template: "extraActions"
          }
        ]
      };
    },
    editing() {
      return {
        allowUpdating: true,
        mode: "cell",
        refreshMode: "repaint",
        useIcons: true
      };
    },
    hasFixedCpts() {
      return this.prefix.fixedCpts.length > 0;
    },
    isSuperUser() {
      return isSuperUser();
    },
    isPathologyNumbering() {
      return this.labSettings.AccessionNumberingType === AccessionNumberingTypeEnum.Pathology;
    },
    contactDataSource() {
      return new DataSource({
        store: DropdownApi.searchContacts,
        sort: [{ selector: "displayName", desc: false }]
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.file_input {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.template_render {
  width: 100%;
  height: 225px;
  overflow: auto;
}
.dropdown {
  max-height: 1.5em;
}
.cptPairGrid {
  width: 25em;
}
.cptPairLabel {
  position: relative;
  z-index: 1000;
  top: 2.5rem;
  left: 1rem;
}
</style>
